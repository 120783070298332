import CatQuality from "../../public/custom-icons/home-custom/cat-quality.svg";
import CatPremium from "../../public/custom-icons/home-custom/cat-premium.svg";
import CatPremiumResized from "../../public/custom-icons/home-custom/cat-premium-resized.svg";
import Files from "../../public/custom-icons/home-custom/files.svg";

const otherSvg = {
    CatPremium,
    CatQuality,
    Files,
    CatPremiumResized,
};

export default otherSvg;
