import { FC, useMemo } from "react";
import { InfoModalContent } from "../../../models/common/common";
import InfoModalContainer from "./InfoModal";
import { randomUid } from "../../../utils/randomIds";

const InfoModalGeneral: FC<{
    content: InfoModalContent;
    show: boolean;
    setShow: (state: boolean) => void;
}> = ({ content, show, setShow }) => {
    const createMarkup = (html: string) => {
        return { __html: html };
    };

    const getBody = (text: string[], list: string[], textAsHtml: boolean) => {
        return (
            <>
                {textAsHtml ? (
                    <div
                        key={randomUid()}
                        dangerouslySetInnerHTML={createMarkup(
                            text.join("<br>")
                        )}
                    />
                ) : (
                    text.map(item => <p key={randomUid()}>{item}</p>)
                )}
                {list.length !== 0 && (
                    <>
                        <br />
                        <ul>
                            {list.map(listItem => (
                                <li key={randomUid()}>{listItem}</li>
                            ))}
                        </ul>
                    </>
                )}
            </>
        );
    };

    const bodyContents = useMemo(
        () =>
            getBody(
                content.content,
                content.listElements,
                !!content.textAsHtml
            ),
        [content]
    );

    return (
        <InfoModalContainer title={content.title} show={show} setShow={setShow}>
            {bodyContents}
        </InfoModalContainer>
    );
};

export default InfoModalGeneral;
